import React from 'react'
import NumberFormat from 'react-number-format'
import { Button, InputGroup, InputGroupAddon } from 'reactstrap'

class NumberInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.loantype !== this.props.loantype) {
            this.setState({ value: 0 })
        }
    }
    deduct = () => {
        this.setState((state, props) => {
            if (state.value < this.props.min || state.value - this.props.interval < this.props.min) {
                alert(`Value should be between ${this.props.min} and ${this.props.max === null ? 'Infinity' : this.props.max}`)
                return;
            }
            return { value: state.value - this.props.interval }
        })
    }
    add = () => {
        this.setState((state, props) => {
            if ( (state.value > this.props.max || state.value + this.props.interval > this.props.max) && this.props.max !== null) {
                alert(`Value should be between ${this.props.min} and ${this.props.max}`)
                return;
            }
            return { value: state.value + this.props.interval }
        })
    }
    handleValueChange = (values) => {
        const { floatValue } = values

        if ( (floatValue > this.props.max || floatValue < this.props.min) && this.props.max !== null) {
            alert(`Value should be between ${this.props.min} and ${this.props.max}`)
            this.setState((state, props) => {
                return { value: state.value }
            })
        }
        else {
            this.setState({ value: floatValue })
            this.props.onValueChange(values)
        }
    }
    render() {
        return (
            <div className="d-block">
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <Button color="primary" size="sm" onClick={this.deduct}><span className="text-white">-</span></Button>
                    </InputGroupAddon>
                    <NumberFormat value={this.state.value} allowNegative={false} {...this.props} onValueChange={this.handleValueChange}  />
                    <InputGroupAddon addonType="append">
                        <Button color="primary" size="sm" onClick={this.add}><span className="text-white">+</span></Button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        )
    }
}

NumberInput.defaultProps = {
    interval: 1,
    min: 0,
    max: null
}

export default NumberInput