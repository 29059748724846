import React from 'react'
import { navigate } from '@reach/router'
import { Container as BlockContainer, Header } from '../Blocks'
import { Container, Form, FormGroup, Label, Row, Col, Button, Input } from 'reactstrap'
import NumberFormat from 'react-number-format'
import NumberInput from './Fields/NumberInput'
import { PMT } from 'formulajs'

const baseCurrency = {
    code: 'PHP',
    rate: {
        'USD': 0.02
    }
}

const loanTypes = {
    "Emergency Loan": { name: "Emergency Loan", rate: 24, maximumLoanableAmount: 30000, maximumMonthTerms: 10 },
    //"Member's Easy Emergency Loan": { name: "Member's Easy Emergency Loan", rate: 12, maximumLoanableAmount: 30000, maximumMonthTerms: 12 },
    "Cash Loan": { name: "Cash Loan", rate: 15, maximumLoanableAmount: 5000000, maximumMonthTerms: 60 },
    "Appliance Loan": { name: "Appliance Loan", rate: 14, maximumLoanableAmount: 30000, maximumMonthTerms: null },
    "Memorial Lot Loan": { name: "Memorial Lot Loan", rate: 12, maximumLoanableAmount: null, maximumMonthTerms: 60 },
    "Housing Loan": { name: "Housing Loan", rate: 12, maximumLoanableAmount: 5000000, maximumMonthTerms: 240 },
    "Micro and Small Enterprise Loan": { name: "Micro and Small Enterprise Loan", rate: 24, maximumLoanableAmount: 300000, maximumMonthTerms: 36 },
    "Business Loan": { name: "Business Loan", rate: 11, maximumLoanableAmount: 15000000, maximumMonthTerms: 84 },
    //"Construction Loan": { name: "Construction Loan", rate: 13, maximumLoanableAmount: 5000000, maximumMonthTerms: 60 },
    //"Housing Loan": { name: "Housing Loan", rate: 12, maximumLoanableAmount: 5000000, maximumMonthTerms: 240 },
    //"Business Loan": { name: "Business Loan", rate: 11, maximumLoanableAmount: 15000000, maximumMonthTerms: 84 }
    "Expanded Credit Line (ECL-FEEDS)": { name: "Expanded Credit Line (ECL-FEEDS)", rate: 15, maximumLoanableAmount: 5000000, maximumMonthTerms: 7 },
    "Vehicle Loan - Car": { name: "Vehicle Loan - Car", rate: 13, maximumLoanableAmount: 5000000, maximumMonthTerms: 60 },
    "Vehicle Loan - Motorcycle & Tricycle": { name: "Vehicle Loan - Motorcycle & Tricycle", rate: 14, maximumLoanableAmount: 120000, maximumMonthTerms: 36 },
    "Agrifarm Equipment Loan": { name: "Agrifarm Equipment Loan", rate: 14, maximumLoanableAmount: 1500000, maximumMonthTerms: 36 }
}

const loanCurrencies = [
    { name: 'PHILIPPINE PESO', code: 'PHP' }
]

class LoanCalculator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loanType: "Emergency Loan",
            loanCurrency: '',
            loanStartDate: '',
            loanAmount: 0,
            loanTermMonth: 0,
            loanInterestMonthly: 0,
            monthlyPayment: 0,
            totalInterestPayment: 0,
            totalAmountRepayable: 0
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.calculate();
    }
    handleValueChange = (name, value) => {
        this.setState({ [name]: value.floatValue })
        this.calculate();
    }
    calculate = () => {
        this.setState((state, props) => {
            let interest = loanTypes[state.loanType].rate / 12
            let monthlyPayment = PMT(interest / 100, state.loanTermMonth, state.loanAmount, 0, 0) * -1
            let totalAmountPaid = monthlyPayment * state.loanTermMonth
            return {
                loanInterestMonthly: interest,
                monthlyPayment: monthlyPayment,
                totalAmountRepayable: totalAmountPaid,
                totalInterestPayment: totalAmountPaid - state.loanAmount

            }
        })
    }
    apply = (e) => {
        e.preventDefault()
        navigate('/inquire');
    }
    render() {
        return (
            <BlockContainer>
                <Container>
                    <Row>
                        <Col md="12">
                            <Header headerNormal title="Koopinoy Loan Calculator" underlined>Use the loan repayment calculator below to get an estimate of your monthly installments, total interest cost and total loan cost. To calculate, simply input the loan amount, loan term and the annual interest of the bank / lender you plan to get a loan from.</Header>
                        </Col>
                    </Row>
                    <Form onSubmit={this.apply}>
                        <Row>
                            <Col lg="7" sm="12">
                                <div className="bg-info">
                                    <Row form className="pt-3">
                                        <Col md="6" className="pl-3 pr-3">
                                            <FormGroup>
                                                <Label for="loanType">Loan Type:</Label>
                                                <Input value={this.state.loanType} onChange={this.handleChange} type="select" name="loanType" id="loanType">
                                                    {
                                                        Object.keys(loanTypes).map((loanKey, key) => {
                                                            let loanType = loanTypes[loanKey];
                                                            return <option value={loanKey} key={`${key}-${loanKey}`}>{ loanType.name }</option>
                                                        })
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" className="pl-3 pr-3">
                                            <FormGroup>
                                                <Label for="loanAmount" className="text-center">Loan Amount:</Label>
                                                <NumberInput loantype={this.state.loanType} name="loanAmount" id="loanAmount" max={loanTypes[this.state.loanType].maximumLoanableAmount} min={0} interval={100} onValueChange={ (val) => { this.handleValueChange('loanAmount', val)}} className="form-control text-center" thousandSeparator={true} required />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form className="py-2">
                                        <Col md="6" className="pl-3 pr-3">
                                            <FormGroup>
                                                <Label for="loanCurrency">Currency:</Label>
                                                <Input value={this.state.loanCurrency} onChange={this.handleChange} type="select" name="loanCurrency" id="loanCurrency">
                                                    {
                                                        loanCurrencies.map((currency) => {
                                                            return <option key={currency.code} value={currency.code}>{`${currency.code} - ${currency.name}`}</option>
                                                        })
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" className="pl-3 pr-3">
                                            <FormGroup>
                                                <Label for="loanTermMonth" className="text-center">Loan Term (month):</Label>
                                                <NumberInput loantype={this.state.loanType} name="loanTermMonth" id="loanTermMonth" max={loanTypes[this.state.loanType].maximumMonthTerms} min={0} interval={1} onValueChange={ (val) => { this.handleValueChange('loanTermMonth', val)}} className="form-control text-center" required />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form className="py-2">
                                        <Col md="6" className="pl-3 pr-3">
                                            <FormGroup>
                                                <Label for="loanStartDate">Loan Start Date:</Label>
                                                <Input value={this.state.loanStartDate} onChange={this.handleChange} type="date" id="loanStartDate" name="loanStartDate" required />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" className="pl-3 pr-3">
                                            <FormGroup>
                                                <Label for="loanInterestMonthly" className="text-center">Interest (monthly)</Label>
                                                {/* <NumberInput loantype={this.state.loanType} name="loanInterestMonthly" max={loanTypes[this.state.loanType].rate} min={0} id="loanInterestMonthly" interval={0.1} onValueChange={ (val) => { this.handleValueChange('loanInterestMonthly', val)}} className="form-control text-center" decimalScale={2} required /> */}
                                                <div><NumberFormat value={this.state.loanInterestMonthly} displayType="text" suffix="%" decimalScale={3} /></div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg="5" sm="12">
                                <Container className="text-center py-3">
                                    <div className="pb-3">
                                        <p className="lead text-primary">MONTHLY PAYMENT</p>
                                        <p className="lead text-primary"><b><NumberFormat value={this.state.monthlyPayment} displayType="text" prefix={baseCurrency.code} decimalScale={2} thousandSeparator={true} /></b></p>
                                    </div>
                                    <div className="pb-2">
                                        <Row>
                                            <Col sm="6">
                                                <p>TOTAL INTEREST PAYMENT</p>
                                                <p className="lead"><b><NumberFormat value={this.state.totalInterestPayment} displayType="text" prefix={baseCurrency.code} decimalScale={2} thousandSeparator={true} /></b></p>
                                            </Col>
                                            <Col sm="6">
                                                <p>TOTAL AMOUNT REPAYABLE</p>
                                                <p className="lead"><b><NumberFormat value={this.state.totalAmountRepayable} displayType="text" prefix={baseCurrency.code} decimalScale={2} thousandSeparator={true} /></b></p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Button size="lg" color="primary">Apply Now</Button>
                                </Container>
                            </Col>
                        </Row>
                    </Form>
                    <div className="text-left ml-3">
                        <p>**Patronage Refund is not included in KooPinoy Calculator computation.</p>
                        <p>
                            Sample Computation of Patronage Refund. <br />
                            **Assumptions: The average patronage refund rate is 20% for 5 years.
                        </p>
                        <p>
                            Cash Loan Applied: 100,000, Loan Interest Payment:  8,309.97 <br />
                            (Total Interest Payment X 20% = Patronage Refund) <br />
                            Php 8,309.97 x 20% =  1,661.99 <br />
                            Patronage Refund = 1,661.99
                        </p>
                    </div>
                </Container>
            </BlockContainer>
        )
    }
}

export default LoanCalculator